import { ToggleButton, Typography } from "@mui/material"
import { blue, grey, red } from "@mui/material/colors"
import { useField } from "formik"
import { FC, useState } from "react"

export type WorkDay = {
  label: string
  active: boolean
}

export const buttonOptions: WorkDay[] = [
  {
    label: "Sun",
    active: false,
  },
  {
    label: "Mon",
    active: false,
  },
  {
    label: "Tue",
    active: false,
  },
  {
    label: "Wed",
    active: false,
  },
  {
    label: "Thur",
    active: false,
  },
  {
    label: "Fri",
    active: false,
  },
  {
    label: "Sat",
    active: false,
  },
]

export const WeekDayToggleButtons: FC<{
  name: string
}> = ({ name }) => {
  const [{ value }, errors, { setValue }] = useField(name)
  const [workDays, setWorkDays] = useState<WorkDay[]>(value?.length ? value : buttonOptions)

  const toggleButton = (index: number) => {
    const toggled = workDays.map((btn, i) => ({ ...btn, active: i === index ? !btn.active : btn.active }))
    setWorkDays(toggled)
    setValue(toggled, true)
  }

  const getBorderColor = (active: boolean) => {
    if (active) {
      return blue[600]
    }

    if (errors.error) {
      return red[600]
    }

    return grey[400]
  }

  return (
    <div className="flex-col">
      <div className="flex gap-x-2">
        {workDays.map((btn, i) => (
          <ToggleButton
            key={i}
            value={btn.active}
            selected={btn.active}
            onChange={() => toggleButton(i)}
            size="large"
            sx={{ height: 40, fontSize: 16, borderColor: getBorderColor(btn.active) }}
          >
            {btn.label}
          </ToggleButton>
        ))}
      </div>
      {errors?.error && (
        <Typography color={red[600]} sx={{ paddingTop: 1 }}>
          {errors.error}
        </Typography>
      )}
    </div>
  )
}
