import { FC, ReactNode } from "react"
import { classNames } from "../../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
}
export const ModalBody: FC<Props> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        "grow px-4 py-6 overflow-y-auto md:mb-0 md:px-8 max-h-[calc(100vh-218px)] md:max-h-[508px]",
        className
      )}
    >
      {children}
    </div>
  )
}
