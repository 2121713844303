import { FC } from "react"
import { LoadingIndicator } from "./LoadingIndicator"

export const LoadingScreenOverlay: FC = () => {
  return (
    <div className={"absolute top-0 w-full h-full select-none"}>
      <div className={"absolute bg-white top-0 opacity-80 w-full h-full rounded-xl z-10"} />
      <div className={"flex justify-center items-center h-full"}>
        <LoadingIndicator />
      </div>
    </div>
  )
}
