import { TimePickerProps } from "@mui/lab"
import { TimePicker } from "@mui/x-date-pickers-pro"
import { useField } from "formik"

export type DigitalClockTimePickerProps<TDate = Date> = {
  name: string
  required?: boolean
  placeholder?: string
  minDateTime?: TDate
  maxDateTime?: TDate
} & Omit<TimePickerProps<TDate>, "onChange" | "value">

export const DigitalClockTimePicker = (props: DigitalClockTimePickerProps) => {
  const { format, name, placeholder, required, ...rest } = props
  const [field, _, { setValue }] = useField(name)

  let val = field.value
  if (val && typeof val === "string") {
    val = new Date(val)
  }

  return (
    <TimePicker
      {...(rest as TimePickerProps<Date>)}
      format={format || "h:mm aa"}
      onChange={(val) => setValue(val)}
      timeSteps={{ hours: 1, minutes: 15 }}
      minTime={props.minDateTime || undefined}
      maxTime={props.maxDateTime || undefined}
      slotProps={{
        textField: {
          size: "small",
          required: required || undefined,
          placeholder: placeholder || undefined,
          // helperText: error || undefined,
        },
        ...(rest.slotProps || {}),
      }}
      value={field.value ?? null}
    />
  )
}
