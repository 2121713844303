import { Slide } from "@mui/material"
import Button, { ButtonTypeMap } from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import { useTheme } from "@mui/material/styles"
import { TransitionProps } from "@mui/material/transitions"
import useMediaQuery from "@mui/material/useMediaQuery"
import { FC, ReactNode, forwardRef } from "react"
import { BiX } from "react-icons/bi"
import { classNames } from "../../helpers/classNames"
import { LoadingContainer } from "../Loading/LoadingContainer"

type Props = {
  cancelButtonText?: string
  children: ReactNode
  contentLabel: ReactNode
  disabled?: boolean
  disableSaveButton?: boolean
  handleCloseModal: () => void
  isLoading?: boolean
  isOpen: boolean
  submitButtonColor?: ButtonTypeMap["props"]["color"]
  submitButtonText?: string
  submitForm?: () => void
  variant?: "standard" | "large"
}

export const MuiModal: FC<Props> = ({
  cancelButtonText = "Cancel",
  children,
  contentLabel,
  disabled = false,
  disableSaveButton = false,
  handleCloseModal,
  isLoading = false,
  isOpen,
  submitButtonColor = "primary",
  submitButtonText,
  submitForm = () => null,
  variant = "standard",
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="dialog-title"
      PaperProps={{
        sx: {
          height: variant === "large" ? "100%" : "auto",
          maxWidth: variant === "large" ? "100%" : "auto",
          minWidth: 500,
          minHeight: 400
        },
        className: classNames(!fullScreen && "rounded-xl", fullScreen && "top-3 !h-[calc(100%-1.5rem)]"),
      }}
      fullWidth={variant === "large"}
      TransitionComponent={fullScreen ? Transition : undefined}
    >
      <LoadingContainer isLoading={isLoading} className={"h-full flex flex-col"}>
        <div className="flex justify-between items-center">
          <DialogTitle className="shrink truncate" variant="h3" id="dialog-title">
            {contentLabel}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleCloseModal} className="mr-2.5">
            <BiX className="w-7 h-7" />
          </IconButton>
        </div>
        <DialogContent
          dividers
          className={classNames(
            "grow px-4 py-6 overflow-y-auto md:mb-0 md:px-8",
            variant == "large" ? "md:max-h-[calc(95vh-140px)] h-full" : "md:max-h-[508px]"
          )}
        >
          {children}
        </DialogContent>

        <DialogActions className="p-4 md:px-8 flex justify-end">
          <Button
            size="large"
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
            className={classNames(submitButtonColor !== "primary" && "text-gray-800", "px-8 font-medium")}
          >
            {cancelButtonText || "Cancel"}
          </Button>
          {!disableSaveButton && (
            <Button
              className="font-medium"
              color={submitButtonColor}
              disabled={disabled || isLoading}
              onClick={submitForm}
              size="large"
              type="submit"
              variant="contained"
            >
              {submitButtonText || "Save"}
            </Button>
          )}
        </DialogActions>
      </LoadingContainer>
    </Dialog>
  )
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})
