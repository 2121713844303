import { MenuItem } from "@mui/material"
import { format, parse } from "date-fns"
import { Form, Formik } from "formik"
import { FC } from "react"
import * as Yup from "yup"
import { ScheduledBreak, useCreateOrEditScheduledBreakQuery } from "../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../graphql/generated/gql"
import { ModalProps } from "../../hooks/useModalProps"
import { PickPlus } from "../../types/helpers"
import { CreatableAutocomplete, CreatableAutocompleteOption } from "../Formik/CreatableAutocomplete"
import { DigitalClockTimePicker } from "../Formik/DigitalClockTimePicker"
import { TextField } from "../Formik/TextField"
import { MuiModal } from "../Modals/MuiModal"

export const CreateOrEditScheduledBreakDocument = graphql(`
  query CreateOrEditScheduledBreak {
    breakTasks {
      id
      name
      isUnpaid
    }
  }
`)

const defaultBreakNames = ["Lunch break", "Break"]

type BreakExpectations = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "localizedStartTime" | "name" | "isActive"
> & {
  breakTask: PickPlus<ScheduledBreak["breakTask"], "id" | "name"> & { name?: string | undefined }
}

export const ScheduledBreakCreateOrEditModal: FC<{
  modalProps: ModalProps
  scheduledBreak?: BreakExpectations

  onConfirm: (
    newBreak:
      | BreakExpectations
      | { durationInMinutes: number; localizedStartTime: string; name: string; breakTaskId: string }
  ) => void
}> = ({ modalProps, scheduledBreak, onConfirm }) => {
  const [{ data }] = useCreateOrEditScheduledBreakQuery()
  const autocompleteOptions: CreatableAutocompleteOption[] = defaultBreakNames.map((name) => ({
    label: name,
    id: name,
  }))

  if (scheduledBreak) {
    if (!defaultBreakNames.includes(scheduledBreak.name))
      autocompleteOptions.push({ label: scheduledBreak.name, id: scheduledBreak.name })
  }

  return (
    <Formik<{
      id?: string | undefined
      name: string | ""
      localizedStartTime: Date | ""
      durationInMinutes: string | ""
      breakTaskId: string | ""
    }>
      initialValues={{
        ...scheduledBreak,
        name: scheduledBreak?.name || "",
        localizedStartTime: scheduledBreak?.localizedStartTime
          ? parse(scheduledBreak.localizedStartTime, "HH:mm", new Date())
          : "",
        durationInMinutes: scheduledBreak?.durationInMinutes.toString() || "",
        breakTaskId: scheduledBreak?.breakTask?.id || "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        localizedStartTime: Yup.date().required("Required"),
        durationInMinutes: Yup.number().required("Required"),
        breakTaskId: Yup.string().oneOf((data?.breakTasks || []).map((t) => t.id)),
      })}
      onSubmit={({ name, localizedStartTime, durationInMinutes, breakTaskId }, { setFieldError }) => {
        if (localizedStartTime === "") {
          setFieldError("localizedStartTime", "Empty string not valid")
          return
        }

        if (scheduledBreak) {
          return onConfirm({
            ...scheduledBreak,
            durationInMinutes: parseInt(durationInMinutes),
            localizedStartTime: format(localizedStartTime, "HH:mm"),
            name,
            breakTaskId,
          })
        }

        return onConfirm({
          durationInMinutes: parseInt(durationInMinutes),
          localizedStartTime: format(localizedStartTime, "HH:mm"),
          name,
          breakTaskId,
        })
      }}
    >
      {({ submitForm, resetForm }) => {
        return (
          <MuiModal
            isOpen={modalProps.isOpen}
            handleCloseModal={() => {
              resetForm()
              modalProps.handleCloseModal()
            }}
            contentLabel={scheduledBreak ? "Edit break" : "Add organization break"}
            submitButtonText="Save"
            submitForm={submitForm}
          >
            <Form className="flex flex-col gap-2">
              <CreatableAutocomplete
                defaultValue={scheduledBreak?.name}
                required
                name="name"
                label="Break name"
                options={autocompleteOptions}
              />
              <TextField select name="breakTaskId" label="Task" required>
                {(data?.breakTasks || []).map((task) => (
                  <MenuItem key={task.id} value={task.id}>
                    {task.name}
                  </MenuItem>
                ))}
              </TextField>
              <div className="flex flex-row gap-4 md:min-w-[400px]">
                <DigitalClockTimePicker className="w-[50%]" label="Start time" name="localizedStartTime" required />
                <TextField className="w-[50%]" name="durationInMinutes" label="Duration" select required>
                  <MenuItem value={15}>15 minutes</MenuItem>
                  <MenuItem value={30}>30 minutes</MenuItem>
                  <MenuItem value={45}>45 minutes</MenuItem>
                  <MenuItem value={60}>1 hour</MenuItem>
                </TextField>
              </div>
            </Form>
          </MuiModal>
        )
      }}
    </Formik>
  )
}
