import { Button } from "@mui/material"
import { Form, Formik } from "formik"
import { FC } from "react"
import * as Yup from "yup"
import { ModalProps } from "../../hooks/useModalProps"
import { FormikDateRangePicker } from "../Formik/DateRangePicker"
import { TextField } from "../Formik/TextField"
import { ModalBody } from "../Modals/ModalBody"
import { ModalFooter } from "../Modals/ModalFooter"
import StandardModal from "../Modals/StandardModal"

export type NonWorkDayModalValues = {
  id?: string | undefined
  name: string
  dateRange: Date[]
  active: boolean
}

const validationSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  name: Yup.string().required("Required"),
  dateRange: Yup.array().of(Yup.date()).min(2).required("Required"),
})

export const NonWorkDayModal: FC<{
  modalProps: ModalProps
  nonWorkDay: NonWorkDayModalValues | undefined
  onSave: (values: NonWorkDayModalValues) => void
}> = ({ modalProps, nonWorkDay, onSave }) => {
  return (
    <Formik<NonWorkDayModalValues>
      validationSchema={validationSchema}
      initialValues={{
        id: nonWorkDay?.id || undefined,
        name: nonWorkDay?.name || "",
        dateRange: nonWorkDay?.dateRange || [],
        active: nonWorkDay?.active || true,
      }}
      onSubmit={onSave}
      enableReinitialize
    >
      {({ resetForm }) => {
        return (
          <StandardModal {...modalProps}>
            <Form>
              <ModalBody>
                <div className="grid">
                  <TextField name="name" label="Name" />
                  <FormikDateRangePicker name="dateRange" />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    modalProps.handleCloseModal()
                    resetForm()
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </ModalFooter>
            </Form>
          </StandardModal>
        )
      }}
    </Formik>
  )
}
