import { Button, Switch, Typography } from "@mui/material"
import { useField } from "formik"
import { FC, useEffect, useState } from "react"
import { BiDotsHorizontalRounded, BiEdit } from "react-icons/bi"
import { useModalProps } from "../../hooks/useModalProps"
import { H4 } from "../Elements"
import { MenuItem, QuickMenuMui } from "../QuickMenuMui"
import { NonWorkDayModal, NonWorkDayModalValues } from "./NonWorkDayModal"

type NonWorkDay = {
  id?: string | undefined
  name: string
  dateRange: Date[]
  active: boolean
}

export const NonWorkDays: FC<{
  fieldName: string
}> = ({ fieldName }) => {
  const [{ value }, _errors, helpers] = useField(fieldName)
  const modalProps = useModalProps("Non-Work Days")

  const [nonWorkDays, setNonWorkDays] = useState<NonWorkDay[]>(value ? value : [])
  const [sortedNonWorkDays, setSortedNonWorkDays] = useState<NonWorkDay[]>([])
  const [nonWorkDayToEdit, setNonWorkDayToEdit] = useState<NonWorkDay | undefined>(undefined)

  useEffect(() => {
    const sorted = nonWorkDays.sort((a, b) => a.dateRange[0].valueOf() - b.dateRange[0].valueOf())

    setSortedNonWorkDays(sorted)
    helpers.setValue(sorted)
  }, [nonWorkDays, helpers])

  const handleAdd = () => modalProps.handleOpenModal()

  const handleSave = (values: NonWorkDayModalValues) => {
    if (nonWorkDayToEdit) {
      const index = nonWorkDays.findIndex((day) => day === nonWorkDayToEdit)

      if (index >= 0) {
        const updatedNonWorkDays = [...nonWorkDays]

        updatedNonWorkDays[index] = { ...values, active: true }

        setNonWorkDays(updatedNonWorkDays)
      }
    } else {
      setNonWorkDays([...nonWorkDays, { ...values, active: true }])
    }

    setNonWorkDayToEdit(undefined)
    modalProps.handleCloseModal()
  }

  const toggleActive = (index: number) => {
    const updated = sortedNonWorkDays.map((day, i) => (i === index ? { ...day, active: !day.active } : day))

    setSortedNonWorkDays(updated)
    helpers.setValue(updated)
  }

  return (
    <div className="w-full">
      <div className="flex justify-between items-baseline gap-x-2">
        <H4>Non-Work Days</H4>
        <Button variant="text" color="primary" onClick={handleAdd}>
          Add
        </Button>
      </div>
      <div className="flex-col gap-y-4">
        {sortedNonWorkDays?.map((day, i) => {
          const [start, end] = day.dateRange
          const menuItems: MenuItem[][] = [
            [
              {
                value: "Edit",
                onClick: () => {
                  setNonWorkDayToEdit(day)
                  modalProps.handleOpenModal()
                },
                Icon: BiEdit,
              },
            ],
          ]

          return (
            <div key={i} className="flex items-center gap-x-2">
              <Switch checked={day.active} onChange={() => toggleActive(i)} />
              <Typography>{day.name}</Typography>
              <Typography color="GrayText">
                {start.toLocaleDateString()} - {end.toLocaleDateString()}
              </Typography>
              <QuickMenuMui items={menuItems}>
                <BiDotsHorizontalRounded className="w-6 h-6" />
              </QuickMenuMui>
            </div>
          )
        })}
      </div>

      {sortedNonWorkDays?.length ? <></> : <Typography color="GrayText">No non-work days set</Typography>}

      {modalProps.isOpen && (
        <NonWorkDayModal
          modalProps={{
            ...modalProps,
            handleCloseModal: () => {
              modalProps.handleCloseModal()
              setNonWorkDayToEdit(undefined)
            },
          }}
          nonWorkDay={nonWorkDayToEdit}
          onSave={handleSave}
        />
      )}
    </div>
  )
}
