import { FC, ReactNode } from "react"
import { classNames } from "../../helpers/classNames"
import { useSpinDelay } from "../../hooks/useSpinDelay"
import { LoadingScreenOverlay } from "./LoadingScreenOverlay"

type Props = {
  children: ReactNode
  isLoading: boolean
  className?: string
}

export const LoadingContainer: FC<Props> = ({ isLoading, children, className }) => {
  const loading = useSpinDelay(isLoading)

  return (
    <div className={classNames("relative h-full", className)}>
      {children}
      {loading && <LoadingScreenOverlay />}
    </div>
  )
}
