import { Chip, Typography } from "@mui/material"
import { addMinutes, format, isEqual, startOfDay } from "date-fns"
import { FC } from "react"
import { slate } from "tailwindcss/colors"
import {
  OrgScheduleDetailsQuery,
  ScheduleTemplate,
  ScheduledBreak,
} from "../../../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../../../types/helpers"
import { LabeledSection } from "../../../../LabeledSection"
import { calculateNumberOfWorkingDays } from "../../../../Scheduling/helpers"
import { ValueOrNoneLabel } from "../../../Assets/BasicInfo"

export type Schedule = OrgScheduleDetailsQuery["scheduleTemplates"][0]
export type WorkDays = Schedule["workDays"]
export type WorkHours = Schedule["workHours"]
export type NonWorkDays = Schedule["nonWorkDays"]

type ScheduleBreak = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "isActive" | "localizedStartTime" | "name"
> & { breakTask: PickPlus<ScheduledBreak["breakTask"], "id" | "name"> }

export const ScheduleDetails: FC<{
  schedule?: ScheduleTemplate
  scheduledBreaks?: ScheduleBreak[]
  startDate?: Date | null
  endDate?: Date | null
  estimatedHours?: number | string | null
  showDaysDatesAndManHours?: boolean
}> = ({ schedule, scheduledBreaks, startDate, endDate, estimatedHours, showDaysDatesAndManHours = false }) => {
  return (
    <div className="flex-col grid gap-y-2">
      {showDaysDatesAndManHours && (
        <DaysDatesAndManHours
          schedule={schedule}
          startDate={startDate}
          endDate={endDate}
          estimatedHours={estimatedHours}
        />
      )}

      <WorkingDays days={schedule?.workDays} />

      <WorkingHours workHours={schedule?.workHours} />

      <WorkingBreaks scheduledBreaks={scheduledBreaks || []} />

      <NonWorkDays nonWorkDays={schedule?.nonWorkDays} />
    </div>
  )
}

const DaysDatesAndManHours: FC<{
  schedule?: ScheduleTemplate
  startDate?: Date | null
  endDate?: Date | null
  estimatedHours?: number | string | null
}> = ({ schedule, startDate, endDate, estimatedHours }) => {
  return (
    <div className="mb-1 flex-col grid gap-y-2">
      <LabeledSection label="Start & End Date">
        <ValueOrNoneLabel
          value={`${startDate?.toLocaleDateString() || "None"} – ${endDate?.toLocaleDateString() || "None"}`}
        />
      </LabeledSection>
      <LabeledSection label="Work Days">
        <Typography color={slate[500]}>{getNumberOfWorkingDays(schedule, startDate, endDate)}</Typography>
      </LabeledSection>
      <LabeledSection label="Man-Hours">
        <ValueOrNoneLabel value={estimatedHours} />
      </LabeledSection>
    </div>
  )
}

const getNumberOfWorkingDays = (schedule?: Schedule, startDate?: Date | null, endDate?: Date | null) => {
  if (!schedule || !schedule?.workDays || !startDate || !endDate) {
    return "None"
  }

  const nonWorkDays: Date[] = schedule?.nonWorkDays?.flatMap((nwd) => nwd.dateRange) || []

  return `${calculateNumberOfWorkingDays(schedule.workDays, startDate, endDate, nonWorkDays)} days`
}

const WorkingDays: FC<{ days?: WorkDays }> = ({ days }) => {
  const activeDays = days?.filter((day) => day.active)

  return (
    <LabeledSection label="Work Days" hideLabel circularIcon>
      {activeDays?.length ? (
        activeDays.map((day, i) => <Chip key={i} label={day.label} sx={{ marginY: 1, marginRight: "4px" }} />)
      ) : (
        <Chip label="None" sx={{ marginY: 1 }} />
      )}
    </LabeledSection>
  )
}

const getFormattedTime = (time: string | Date | null | undefined) => {
  if (!time) {
    return "None"
  }

  if (typeof time !== "string") {
    time = format(time, "hh:mm")
  }

  const [hours, minutes] = time.split(":")
  const date = new Date(new Date().setHours(+hours, +minutes))

  return format(date, "h:mm a")
}

const WorkingHours: FC<{ workHours?: WorkHours }> = ({ workHours }) => {
  const hours = workHours?.hours || "None"
  const startTime = getFormattedTime(workHours?.startTime)
  const endTime = getFormattedTime(workHours?.endTime)

  return (
    <LabeledSection label="Work Hours" hideLabel circularIcon>
      {!workHours?.hours ? (
        <Chip label="None" />
      ) : (
        <>
          <Chip
            label={
              <div className="flex gap-x-2">
                <Typography>Start Time</Typography> <Typography color={slate[500]}>{startTime}</Typography>
              </div>
            }
            sx={{ margin: 1 }}
          />
          <Chip
            label={
              <div className="flex gap-x-2">
                <Typography>End Time</Typography> <Typography color={slate[500]}>{endTime}</Typography>
              </div>
            }
            sx={{ margin: 1 }}
          />
          <Chip
            label={
              <div className="flex gap-x-2">
                <Typography>Total Hours</Typography> <Typography color={slate[500]}>{hours}</Typography>
              </div>
            }
            sx={{ margin: 1 }}
          />
        </>
      )}
    </LabeledSection>
  )
}

const NonWorkDays: FC<{ nonWorkDays?: NonWorkDays }> = ({ nonWorkDays }) => {
  const active = nonWorkDays?.filter((day) => day.active)

  return (
    <LabeledSection label="Holidays" hideLabel circularIcon>
      {!active?.length ? (
        <Chip label="None" />
      ) : (
        active.map((nonWorkDay, i) => (
          <Chip key={i} label={getNonWorkDayLabel(nonWorkDay)} sx={{ marginY: "2px", marginRight: "4px" }} />
        ))
      )}
    </LabeledSection>
  )
}

const WorkingBreaks: FC<{ scheduledBreaks: ScheduleBreak[] }> = ({ scheduledBreaks }) => {
  const active = scheduledBreaks?.filter((sb) => sb.isActive)

  return (
    <LabeledSection label="Scheduled Break" hideLabel circularIcon>
      {!active?.length ? (
        <Chip label="None" sx={{ marginY: 1 }} />
      ) : (
        active.map((sb) => <Chip key={sb.id} label={getBreakLabel(sb)} sx={{ marginY: 1, marginRight: "4px" }} />)
      )}
    </LabeledSection>
  )
}

const getNonWorkDayLabel = (nonWorkDay: { name: string; dateRange: Date[]; active: boolean }) => {
  const [start, end] = nonWorkDay.dateRange

  if (isEqual(startOfDay(start), startOfDay(end))) {
    return (
      <div className="flex gap-x-2">
        <Typography>{nonWorkDay.name}</Typography>{" "}
        <Typography color={slate[500]}>{format(start, "MM/dd/yyyy")}</Typography>
      </div>
    )
  }

  return (
    <div className="flex gap-x-2">
      <Typography>{nonWorkDay.name}</Typography>{" "}
      <Typography color={slate[500]}>{`${format(start, "MM/dd/yyyy")} - ${format(end, "MM/dd/yyyy")}`}</Typography>
    </div>
  )
}

const getBreakLabel = (scheduledBreak: ScheduleBreak) => {
  const [hours, minutes] = scheduledBreak.localizedStartTime.split(":")

  const startDate = new Date(new Date().setHours(+hours, +minutes))
  const startTime = format(startDate, "h:mm a")
  const endDate = addMinutes(startDate, scheduledBreak.durationInMinutes)
  const endTime = format(endDate, "h:mm a")

  return (
    <div className="flex gap-x-2">
      <Typography>{scheduledBreak.name}</Typography>{" "}
      <Typography color={slate[500]}>{`${startTime} – ${endTime}`}</Typography>
    </div>
  )
}
