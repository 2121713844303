import { addDays, differenceInCalendarDays, eachDayOfInterval, format, isEqual, isWithinInterval } from "date-fns"
import {
  NewScheduleBreakExpectations,
  ScheduleBreakExpectations,
} from "../Partials/Organizations/OrganizationScheduledBreaks"
import { NonWorkDays } from "../Partials/Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"
import { WorkDay } from "./WeekDayToggleButtons"

type NonWorkDay = {
  id: string
  name: string
  dateRange: Date[]
  active: boolean
}

/**
 *
 * @param option The NonWorkDay
 * @returns A string representing the name and date of the non work day
 */
export const getOptionLabel = (option: NonWorkDay) => {
  if (typeof option === "string") {
    return option
  }

  const [start, end] = option.dateRange

  const formattedStart = format(new Date(`${start}T00:00`), "LLLL dd, yyyy")
  const formattedEnd = format(new Date(`${end}T00:00`), "LLLL dd, yyyy")

  const formattedDateRange = `${formattedStart} – ${formattedEnd}`

  return `${option.name} ${formattedDateRange}`
}

/**
 *
 * @param workDays Days of the week people will be working on the project / task
 * @param startDate The starting day of the project / task
 * @param endDate The end date of the project / task
 * @param nonWorkDays Holidays and days off for the project / task schedule
 * @returns The calculated number of working days for the project / task
 */
export const calculateNumberOfWorkingDays = (
  workDays: WorkDay[],
  startDate: Date | null,
  endDate: Date | null,
  nonWorkDays: Date[]
) => {
  if (!startDate || !endDate) {
    return
  }

  const totalDaysInRange = differenceInCalendarDays(endDate, startDate) + 1 // add 1 for the start date

  // get the day-of-week index for each working day (e.g. M - F is [1,2,3,4,5])
  const workingDaysOfTheWeek = getWorkingDaysOfTheWeek(workDays)

  // https://stackoverflow.com/questions/21985259/how-many-specific-days-within-a-date-range-in-javascript/22122796#22122796
  // https://stackoverflow.com/questions/25562173/calculate-number-of-specific-weekdays-between-dates
  let result = workingDaysOfTheWeek.reduce((totalDaysWorked, workingDay) => {
    const dayOfTheWeekIndex = (startDate.getDay() + 6 - workingDay) % 7
    const totalCountOfDaysForThisDayOfTheWeek = Math.floor((totalDaysInRange + dayOfTheWeekIndex) / 7)

    return totalDaysWorked + totalCountOfDaysForThisDayOfTheWeek
  }, 0)

  if (nonWorkDays?.length) {
    const numberOfHolidaysInWorkingPeriod = nonWorkDays.reduce((total, nonWorkDay) => {
      const holidayIsOnWorkingDay = workingDaysOfTheWeek.some((day) => day === nonWorkDay.getDay())
      const holidayIsInDateRange = isWithinInterval(nonWorkDay, { start: startDate, end: endDate })

      return holidayIsOnWorkingDay && holidayIsInDateRange ? (total += 1) : total
    }, 0)

    result -= numberOfHolidaysInWorkingPeriod
  }

  return result
}

/**
 *
 * @param numberOfWorkingDays total number of days working on the project or task
 * @param startDate The project / task start date
 * @param workDays The days of the week people will be working on the project / task
 * @param nonWorkDays Holidays and other days off from the project
 * @returns The date of the last working day of the project / task
 */
export const calculateEndDate = (
  numberOfWorkingDays: number | null,
  startDate: Date | null,
  workDays: WorkDay[],
  nonWorkDays?: Date[]
) => {
  if (!numberOfWorkingDays || !startDate) {
    return null
  }

  let daysToAddFromStartDate = 0
  let workDaysAdded = 0
  const workingDaysOfTheWeek = getWorkingDaysOfTheWeek(workDays)

  while (workDaysAdded < numberOfWorkingDays) {
    const date = addDays(startDate, daysToAddFromStartDate)
    const dayOfWeekIndex = date.getDay()
    const isHoliday = nonWorkDays?.some((nonWorkDay) => isEqual(nonWorkDay, date))
    const isWorkDay = workingDaysOfTheWeek.some((day) => day === dayOfWeekIndex)

    if (isWorkDay && !isHoliday) {
      workDaysAdded++
    }

    daysToAddFromStartDate++
  }

  const newEndDate = addDays(startDate, daysToAddFromStartDate - 1)

  return newEndDate
}

/**
 *
 * @param workDays An array of WorkDay
 * @returns An array with each day of week index for each active work day
 */
const getWorkingDaysOfTheWeek = (workDays: WorkDay[]) => {
  const workingDaysOfTheWeek = workDays.reduce((arr: number[], day, index) => {
    if (day.active) {
      arr.push(index)
    }

    return arr
  }, [])

  return workingDaysOfTheWeek
}

/**
 *
 * @param nonWorkDays An array of nonWorkDays on a schedule
 * @returns An array of dates for every non work day in a date range
 */
export const getExpandedNonWorkDays = (nonWorkDays: NonWorkDays) => {
  let expandedNonWorkingDays: Date[] = []

  nonWorkDays
    ?.filter((nwd) => nwd.active)
    ?.forEach((nonWorkDay) => {
      const [start, end] = nonWorkDay.dateRange
      expandedNonWorkingDays.push(...eachDayOfInterval({ start, end }))
    })

  return expandedNonWorkingDays
}

/**
 * Function to check if a scheduled break is being edited or not.
 * @param scheduledBreak The scheduled break to check
 * @returns true if the scheduled break is being edited, false if it is new
 */
export const isEditingAnExistingScheduleType = (scheduledBreak: any): scheduledBreak is ScheduleBreakExpectations => {
  return Boolean(scheduledBreak?.breakTask)
}

/**
 * Function to check if a scheduled break is new or not. This is safer than checking !isEditingScheduleType because that function could give a false positive if the scheduled break is missing a breakTaskId.
 * @param scheduledBreak The scheduled break to check
 * @returns true if the scheduled break is new, false if it is being edited
 */
export const isNewScheduleType = (scheduledBreak: any): scheduledBreak is NewScheduleBreakExpectations => {
  return !Boolean(scheduledBreak?.breakTask) && Boolean(scheduledBreak?.breakTaskId)
}
